import { addDays, addMonths, isBefore, max, min } from "date-fns"
import { PaymentAsset } from "@/features/sell"
import { bn, displayFiat } from "@/lib/helpers/numberUtils"
import { getDefaultOrderCreationOpenedAt } from "@/lib/helpers/orders"

export const getDefaultAuctionDuration = (start = new Date()) => {
  return {
    start: max([start, new Date()]),
    end: addDays(start, 7),
  }
}

export const getDefaultListingDuration = (
  start = getDefaultOrderCreationOpenedAt(),
) => {
  const nowDate = getDefaultOrderCreationOpenedAt()
  const defaultEnd = addMonths(start, 1)
  const maxEnd = addMonths(nowDate, 1)
  return {
    start: max([start, nowDate]),
    end: isBefore(start, nowDate) ? maxEnd : min([defaultEnd, maxEnd]),
  }
}

export const getIsGreaterThanMinEnglishAuctionPrice =
  (
    selectedPaymentAsset: PaymentAsset,
    minimumEnglishAuctionUsdPrice: string | undefined,
  ) =>
  (v: string) => {
    if (
      !selectedPaymentAsset.ethPrice ||
      !selectedPaymentAsset.usdPrice ||
      !minimumEnglishAuctionUsdPrice
    ) {
      return true
    }

    const priceInUsd = bn(v).times(selectedPaymentAsset.usdPrice)

    // TODO(i18n): translate error message
    return (
      priceInUsd.isGreaterThanOrEqualTo(minimumEnglishAuctionUsdPrice) ||
      `The price must be greater than ${displayFiat(
        minimumEnglishAuctionUsdPrice,
      )} in value.`
    )
  }
