import React from "react"
import { Block } from "@/design-system/Block"
import { DatePicker } from "@/design-system/DatePicker"
import { Flex } from "@/design-system/Flex"
import { Select } from "@/design-system/Select"
import {
  DateRange,
  DateRangeOptionsVariant,
  useDateRangeOptions,
} from "../hooks/useDateRangeOptions"

type Props = {
  duration?: DateRange
  id: string
  onChange: (value: DateRange | undefined) => unknown
  variant: DateRangeOptionsVariant
}

export const OrderDurationSelect = ({
  duration,
  id,
  onChange,
  variant,
}: Props) => {
  const {
    expirationOptions,
    defaultMaxDate,
    defaultMinDate,
    defaultEndDate,
    endDateOptionValue,
    setEndDateOptionValue,
  } = useDateRangeOptions(variant)

  return (
    <Flex flexDirection={{ _: "column", md: "row" }}>
      <Block
        marginBottom={{ _: "8px", md: undefined }}
        marginRight={{ _: undefined, md: "8px" }}
        maxWidth={{ _: "100%", md: "150px" }}
      >
        <Select
          clearable={false}
          id={id}
          options={expirationOptions}
          overrides={{
            Dropdown: {
              props: {
                overflowY: "scroll",
                placement: "bottom",
                popperOptions: {
                  modifiers: [
                    {
                      name: "flip",
                      enabled: true,
                    },
                  ],
                },
              },
            },
          }}
          readOnly
          value={endDateOptionValue}
          onSelect={value => {
            if (value) {
              setEndDateOptionValue(value.value)
              onChange({
                start: new Date(),
                end: value.date ?? defaultEndDate,
              })
            }
          }}
        />
      </Block>
      <Block flexGrow={1} minWidth={0}>
        <DatePicker
          max={defaultMaxDate}
          min={defaultMinDate}
          overrides={{
            Popover: {
              placement: "bottom",
              popperOptions: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: true,
                  },
                ],
              },
            },
            Button: {
              style: {
                fontWeight: 400,
                width: "100%",
                height: "48px",
              },
            },
          }}
          value={duration ? new Date(duration.end) : undefined}
          withTime
          onChange={value => {
            setEndDateOptionValue("custom")
            onChange({ start: new Date(), end: value })
          }}
        />
      </Block>
    </Flex>
  )
}
