/**
 * @generated SignedSource<<df4a1fb964ca01834987033ac1e5913f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CollectionPriceButtonsNftQuery$variables = {
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value";
  contractAddress: string;
  tokenId: string;
};
export type CollectionPriceButtonsNftQuery$data = {
  readonly nft: {
    readonly topTraitFloor: {
      readonly floor: {
        readonly symbol: string;
        readonly unit: string;
        readonly usd: string;
      };
      readonly trait: {
        readonly traitType: string;
        readonly value: string | null;
      };
    } | null;
  };
};
export type CollectionPriceButtonsNftQuery = {
  response: CollectionPriceButtonsNftQuery$data;
  variables: CollectionPriceButtonsNftQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractAddress"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "contractAddress",
    "variableName": "contractAddress"
  },
  {
    "kind": "Variable",
    "name": "tokenId",
    "variableName": "tokenId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "traitType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "floor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usd",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionPriceButtonsNftQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TraitFloorType",
            "kind": "LinkedField",
            "name": "topTraitFloor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TraitType",
                "kind": "LinkedField",
                "name": "trait",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CollectionPriceButtonsNftQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TraitFloorType",
            "kind": "LinkedField",
            "name": "topTraitFloor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TraitType",
                "kind": "LinkedField",
                "name": "trait",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffeddf58d68c9b7d64bc24fe12649820",
    "id": null,
    "metadata": {},
    "name": "CollectionPriceButtonsNftQuery",
    "operationKind": "query",
    "text": "query CollectionPriceButtonsNftQuery(\n  $tokenId: String!\n  $contractAddress: AddressScalar!\n  $chain: ChainScalar!\n) {\n  nft(tokenId: $tokenId, contractAddress: $contractAddress, chain: $chain) {\n    topTraitFloor {\n      trait {\n        traitType\n        value\n        id\n      }\n      floor {\n        unit\n        symbol\n        usd\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "01dbc19f6c995d402c376bb9bb8f9e46";

export default node;
