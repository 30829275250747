import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { useTranslate } from "@/hooks/useTranslate"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  CreateListingActionModalQuery,
  CreateListingActionModalQuery$variables,
} from "@/lib/graphql/__generated__/CreateListingActionModalQuery.graphql"

export type CreateListingActionModalProps = Omit<
  BaseBlockchainActionProps,
  "title"
> &
  CreateListingActionModalQuery$variables & {
    isLowerListing?: boolean
  }

const BaseCreateListingActionModal = ({
  onEnd,
  onError,
  isLowerListing,
  ...variables
}: CreateListingActionModalProps) => {
  const t = useTranslate("components")
  const data = useLazyLoadQuery<CreateListingActionModalQuery>(
    graphql`
      query CreateListingActionModalQuery(
        $item: AssetQuantityInputType!
        $price: PaymentAssetQuantityInputType!
        $recipient: AddressScalar
        $openedAt: DateTime!
        $closedAt: DateTime!
        $englishAuctionReservePrice: BigNumberScalar
        $optionalCreatorFeeBasisPoints: Int
      ) {
        blockchain {
          createListingActions(
            item: $item
            price: $price
            recipient: $recipient
            openedAt: $openedAt
            closedAt: $closedAt
            englishAuctionReservePrice: $englishAuctionReservePrice
            optionalCreatorFeeBasisPoints: $optionalCreatorFeeBasisPoints
          ) {
            ...BlockchainActionList_data
          }
        }
      }
    `,
    variables,
    { fetchPolicy: "network-only" },
  )

  return (
    <BlockchainActionList
      dataKey={data.blockchain.createListingActions}
      overrides={{
        CreateOrderActionType: {
          props: {
            title: isLowerListing
              ? t(
                  "blockchain.createListingModal.lowerTitle",
                  "Lower your listing",
                )
              : undefined,
          },
        },
      }}
      onEnd={onEnd}
      onError={onError}
    />
  )
}

const CreateListingActionModalWithSuspense = (
  props: CreateListingActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BaseCreateListingActionModal {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const CreateListingActionModal = (
  props: CreateListingActionModalProps,
) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider action="IndividualListing">
      <CreateListingActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
