/* eslint-disable react/no-unused-prop-types */
import React, { useCallback } from "react"
import { BlockchainActionOnEnd } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { CreateListingActionModal } from "@/components/blockchain/orders/CreateListingActionModal"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { Modal } from "@/design-system/Modal"
import {
  MultiStepFlow,
  useMultiStepFlowContext,
} from "@/design-system/Modal/MultiStepFlow.react"
import { SellAsset, SellAssets } from "@/features/sell"
import { SellFormSubmitData } from "@/features/sell/components/SellForm/SellForm.react"
import { getSelectedPaymentAsset } from "@/features/sell/utils/assets"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { sellPageQueries_paymentAssets$data } from "@/lib/graphql/__generated__/sellPageQueries_paymentAssets.graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import { bn } from "@/lib/helpers/numberUtils"
import { getDefaultOrderCreationOpenedAt } from "@/lib/helpers/orders"
import { PriceWarningInfo } from "../../hooks/useSellFlow.react"
import {
  getDefaultExpirationDate,
  validateEndTime,
  validateStartTime,
} from "../SellFlow/utils"
import { PriceWarningModal } from "./PriceWarningModal.react"

export type ListingModalProps = {
  assetsToDisplay: SellAssets
  chain: ChainIdentifier | undefined
  cleanupMultichainModal: () => unknown
  closeMultichainModal: () => unknown
  firstAsset: SellAsset
  getAggregatedPriceWarningInfo: () => PriceWarningInfo
  isMultichainModalOpen: boolean
  isSubmitting: boolean
  itemPath: string
  openMultichainModal: () => unknown
  paymentAssets: sellPageQueries_paymentAssets$data
  setIsSubmitting: (isSubmitting: boolean) => unknown
  setItemPath: (itemPath: string) => unknown
  submittedFormData: SellFormSubmitData | undefined
  onClose?: () => unknown
  onSubmitted?: (orderItemRelayId?: string) => unknown
}

type Props = {
  listingModalProps: ListingModalProps
}

type SellFlowMultiStepModalContentProps = {
  close: () => unknown
  renderListingModal: () => JSX.Element
  getAggregatedPriceWarningInfo: () => PriceWarningInfo
}

export const SellFlowMultiStepModalContent = ({
  getAggregatedPriceWarningInfo,
  close,
  renderListingModal,
}: SellFlowMultiStepModalContentProps) => {
  const t = useTranslate("sell")
  const { onNext } = useMultiStepFlowContext()
  const aggregatedPriceWarningInfo = getAggregatedPriceWarningInfo()

  if (aggregatedPriceWarningInfo.showPriceWarningModal) {
    return (
      <PriceWarningModal
        priceWarningActionMessage={t("flow.confirmListing", "Confirm listing")}
        priceWarningHeader={t(
          "flow.confirmLowListing",
          "Confirm low listing price?",
        )}
        priceWarningMessage={
          aggregatedPriceWarningInfo.priceWarningModalMessage
        }
        onClose={close}
        onConfirm={() => onNext(renderListingModal())}
      />
    )
  } else {
    return renderListingModal()
  }
}

export const SellFlowMultiStepModal = ({ listingModalProps }: Props) => {
  const router = useRouter()
  const {
    itemPath,
    cleanupMultichainModal,
    getAggregatedPriceWarningInfo,
    isSubmitting,
    isMultichainModalOpen,
    openMultichainModal,
  } = listingModalProps

  const renderListingModal = useCallback(() => {
    openMultichainModal()
    return <MultichainListingModalContent {...listingModalProps} />
  }, [openMultichainModal, listingModalProps])

  const onClose = () => {
    cleanupMultichainModal()
    if (itemPath) {
      router.push(itemPath)
    }
  }

  return (
    <Modal
      isOpen={isSubmitting || isMultichainModalOpen}
      size="large"
      onClose={onClose}
    >
      <MultiStepFlow>
        <SellFlowMultiStepModalContent
          close={onClose}
          getAggregatedPriceWarningInfo={getAggregatedPriceWarningInfo}
          renderListingModal={renderListingModal}
        />
      </MultiStepFlow>
    </Modal>
  )
}

export const MultichainListingModalContent = ({
  submittedFormData,
  paymentAssets,
  firstAsset,
  setIsSubmitting,
  setItemPath,
  assetsToDisplay,
  onClose,
  onSubmitted,
}: ListingModalProps) => {
  const t = useTranslate("sell")
  const { showErrorMessage } = useToasts()
  const { onReplace } = useMultiStepFlowContext()

  if (!submittedFormData) {
    return null
  }

  const {
    price,
    quantity,
    selectedPaymentAssetRelayId,
    duration,
    reservedBuyerAddress,
    auctionType,
    reservePrice,
    optionalCreatorFeeBasisPoints,
  } = submittedFormData

  const paymentAsset = getSelectedPaymentAsset(
    paymentAssets,
    selectedPaymentAssetRelayId,
  )

  const openedAt = validateStartTime(duration.start)
    ? duration.start.toISOString()
    : getDefaultOrderCreationOpenedAt().toISOString()

  const closedAt = validateEndTime(duration.start, duration.end)
    ? duration.end.toISOString()
    : getDefaultExpirationDate().toISOString()

  const englishAuctionReservePrice =
    auctionType === "english" ? (reservePrice || price).toString() : undefined

  const priceInput = {
    paymentAsset: paymentAsset.relayId,
    amount: bn(price).times(quantity).toString(),
  }

  const onError = (error?: Error) => {
    showErrorMessage(
      error?.message ||
        t(
          "flow.defaultErrorMessage",
          "Something went wrong while creating a listing",
        ),
    )
  }

  const onEnd: BlockchainActionOnEnd = ({ transaction, createdOrder } = {}) => {
    const itemPath = getAssetUrl(firstAsset)

    onReplace(
      <AssetSuccessModalContent
        itemName={undefined}
        itemUrl={itemPath}
        mode="listed"
        transaction={transaction}
        variables={{
          assetIDs: assetsToDisplay.map(({ relayId }) => relayId),
        }}
        onClose={onClose}
      />,
    )
    setItemPath(itemPath)
    setIsSubmitting(false)
    onSubmitted?.(createdOrder?.item.relayId)
  }

  return (
    <CreateListingActionModal
      closedAt={closedAt}
      englishAuctionReservePrice={englishAuctionReservePrice}
      item={{ asset: firstAsset.relayId, quantity: quantity.toString() }}
      openedAt={openedAt}
      optionalCreatorFeeBasisPoints={optionalCreatorFeeBasisPoints}
      price={priceInput}
      recipient={reservedBuyerAddress}
      onEnd={onEnd}
      onError={onError}
    />
  )
}
