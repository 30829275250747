import React, { useState, useCallback, useMemo } from "react"
import { Text } from "@opensea/ui-kit"
import { range } from "lodash"
import { DeepMap, DeepPartial } from "react-hook-form"
import { useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { SellAssets } from "@/features/sell"
import { SellAnalyticsTracker } from "@/features/sell/analytics"
import {
  SellForm,
  SellFormData,
  SellFormSubmitData,
  SellFormDefaultValues,
} from "@/features/sell/components/SellForm"
import { SellAsset } from "@/features/sell/types"
import { getSelectedPaymentAsset } from "@/features/sell/utils/assets"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { sellPageQueries_asset$key } from "@/lib/graphql/__generated__/sellPageQueries_asset.graphql"
import { sellPageQueries_paymentAssets$key } from "@/lib/graphql/__generated__/sellPageQueries_paymentAssets.graphql"
import { useSellFlow_chain$key } from "@/lib/graphql/__generated__/useSellFlow_chain.graphql"
import { useSellFlow_tradeLimits$key } from "@/lib/graphql/__generated__/useSellFlow_tradeLimits.graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import {
  BigNumber,
  bn,
  display,
  displayUSD,
  getMaxDecimals,
  normalizePriceDisplay,
  NumberInput,
} from "@/lib/helpers/numberUtils"
import { NBSP } from "@/lib/helpers/stringUtils"
import { PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD } from "../components/SellFlowMultiStepModal/PriceWarningModal.react"
import { ListingModalProps } from "../components/SellFlowMultiStepModal/SellFlowMultiStepModal.react"
import {
  SellPagePaymentAssetsFragment,
  SellPageAssetFragment,
} from "../queries/sellPageQueries"

type Args = {
  assetDataKeys: sellPageQueries_asset$key | null
  defaultFormValues: SellFormDefaultValues
  paymentAssetsDataKey: sellPageQueries_paymentAssets$key | null
  tradeLimitsDataKey: useSellFlow_tradeLimits$key | null
  setPrice: (price: number) => unknown
  selectedPaymentAssetRelayId: string
  setSelectedPaymentAssetRelayId: (relayId: string) => unknown
  isSubmitting: boolean
  setIsSubmitting: (isSubmitting: boolean) => unknown
  submitRef?: React.MutableRefObject<HTMLButtonElement | null>
  isDisplayedInModal?: boolean
  chain: useSellFlow_chain$key | null
}

export type PriceWarningInfo = {
  showPriceWarningModal: boolean | undefined
  belowFloorPrice: boolean | undefined
  belowFloorPriceMessage: React.ReactNode | undefined
  priceWarningModalMessage: React.ReactNode | undefined
}

export enum WarningSeverity {
  High,
  Low,
}

export type WatchedFormState = {
  fieldValues: Pick<Partial<SellFormData>, "duration" | "quantity">
  disabled: boolean
  dirtyFields: DeepPartial<DeepMap<SellFormData, boolean | undefined>>
}

export type UseSellFlow = {
  tracker: SellAnalyticsTracker
  renderForm: () => React.ReactNode
  listingModalProps: ListingModalProps
  watchedFormState: WatchedFormState
}

export const useSellFlow = ({
  assetDataKeys,
  defaultFormValues,
  paymentAssetsDataKey,
  tradeLimitsDataKey,
  selectedPaymentAssetRelayId,
  setSelectedPaymentAssetRelayId,
  setPrice,
  isSubmitting,
  setIsSubmitting,
  submitRef,
  isDisplayedInModal,
  chain: chainDataKey,
}: Args): UseSellFlow | undefined => {
  const t = useTranslate("sell")
  const router = useRouter()
  const tradeLimits = useFragment(
    graphql`
      fragment useSellFlow_tradeLimits on TradeLimitsType {
        ...SellForm_tradeLimits
      }
    `,
    tradeLimitsDataKey,
  )
  const chain = useFragment(
    graphql`
      fragment useSellFlow_chain on ChainType {
        identifier
        wrappedCurrency {
          symbol
        }
      }
    `,
    chainDataKey,
  )

  const assetsData = useFragment(SellPageAssetFragment, assetDataKeys)
  const [assets, setAssets] = useState<SellAssets>(assetsData || [])
  const [submittedFormData, setSubmittedFormData] =
    useState<SellFormSubmitData>()
  const [itemPath, setItemPath] = useState("")
  const {
    isOpen: isMultichainModalOpen,
    close: closeMultichainModal,
    open: openMultichainModal,
  } = useIsOpen()
  const cleanupMultichainModal = useCallback(() => {
    closeMultichainModal()
    setIsSubmitting(false)
  }, [closeMultichainModal, setIsSubmitting])
  const [watchedFormState, setWatchedFormState] = useState<WatchedFormState>({
    fieldValues: {},
    disabled: true,
    dirtyFields: {},
  })
  const onWatch = useCallback(
    (values: WatchedFormState) => setWatchedFormState(values),
    [setWatchedFormState],
  )

  const itemsToDisplay = assets.slice(0, 1)

  const tracker = useMemo(
    () =>
      new SellAnalyticsTracker({
        eventSource: "SellFlow",
        assetIds: itemsToDisplay.map(asset => asset.relayId),
        collectionSlugs: itemsToDisplay.map(asset => asset.collection.slug),
      }),
    [itemsToDisplay],
  )

  useUpdateEffect(() => {
    setAssets(assetsData || [])
  }, [assetsData])

  const paymentAssets = useFragment(
    SellPagePaymentAssetsFragment,
    paymentAssetsDataKey,
  )

  useMountEffect(() => {
    tracker.open()

    const firstUnownedAsset = assets.find(asset => asset.ownedQuantity === "0")
    if (firstUnownedAsset) {
      router.replace(getAssetUrl(firstUnownedAsset))
    }
  })

  const getPriceWarningInfo = useCallback(
    (price: NumberInput): PriceWarningInfo => {
      if (!paymentAssets || !selectedPaymentAssetRelayId) {
        return {
          showPriceWarningModal: undefined,
          belowFloorPrice: undefined,
          belowFloorPriceMessage: undefined,
          priceWarningModalMessage: undefined,
        }
      }

      const selectedPaymentAsset = getSelectedPaymentAsset(
        paymentAssets,
        selectedPaymentAssetRelayId,
      )
      for (const index of range(itemsToDisplay.length)) {
        const { statsV2: stats, nativePaymentAsset } =
          itemsToDisplay[index].collection
        const itemFloorPrice = stats.floorPrice?.unit

        if (itemFloorPrice && selectedPaymentAsset.usdSpotPrice) {
          const itemFloorPriceUsd = bn(stats.floorPrice.usd).decimalPlaces(
            getMaxDecimals("USD"),
            BigNumber.ROUND_FLOOR,
          )
          const priceUsd = bn(price)
            .multipliedBy(selectedPaymentAsset.usdSpotPrice)
            .decimalPlaces(getMaxDecimals("USD"), BigNumber.ROUND_FLOOR)
          const floorPriceDisplay =
            selectedPaymentAsset.symbol === nativePaymentAsset.symbol
              ? `${display(itemFloorPrice, nativePaymentAsset.symbol)}${NBSP}${
                  nativePaymentAsset.symbol
                }`
              : `$${displayUSD(itemFloorPriceUsd)}${NBSP}USD`

          if (priceUsd.lt(itemFloorPriceUsd)) {
            const percentageBelowFloorPrice = BigNumber.min(
              bn(1).minus(priceUsd.div(itemFloorPriceUsd)),
              0.99,
            ).multipliedBy(100)
            const showPriceWarningModal =
              percentageBelowFloorPrice.isGreaterThan(
                PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD,
              )

            const belowFloorPriceMessage = (
              <BelowFloorPriceText
                warningSeverity={
                  showPriceWarningModal
                    ? WarningSeverity.High
                    : WarningSeverity.Low
                }
              >
                {t(
                  "flow.priceBelowFloorMessage",
                  "Price is below collection floor price of {{floorPriceDisplay}}",
                  { floorPriceDisplay },
                )}
              </BelowFloorPriceText>
            )

            const priceWarningModalMessage = showPriceWarningModal ? (
              <div>
                {t(
                  "flow.priceBelowFloorDetailPrompt",
                  "This listing will be {{percent}} below the floor price for this collection.",
                  {
                    percent: (
                      <Text>
                        {normalizePriceDisplay(percentageBelowFloorPrice, 0)}%
                      </Text>
                    ),
                  },
                )}
              </div>
            ) : (
              ""
            )
            return {
              showPriceWarningModal,
              belowFloorPrice: true,
              belowFloorPriceMessage,
              priceWarningModalMessage,
            }
          }
        }
      }

      return {
        showPriceWarningModal: false,
        belowFloorPrice: false,
        belowFloorPriceMessage: "",
        priceWarningModalMessage: "",
      }
    },
    [itemsToDisplay, paymentAssets, selectedPaymentAssetRelayId, t],
  )

  const getAggregatedPriceWarningInfo = useCallback(() => {
    if (!submittedFormData) {
      return {
        showPriceWarningModal: undefined,
        belowFloorPrice: undefined,
        belowFloorPriceMessage: undefined,
        priceWarningModalMessage: undefined,
        priceType: undefined,
      }
    }

    const { price, reservePrice } = submittedFormData

    const priceWarningInfoArr = [getPriceWarningInfo(price)]
    if (reservePrice) {
      priceWarningInfoArr.push(getPriceWarningInfo(reservePrice))
    }

    return priceWarningInfoArr.reduce((prev, curr) => {
      return {
        belowFloorPrice: prev.belowFloorPrice || curr.belowFloorPrice,
        belowFloorPriceMessage:
          prev.belowFloorPriceMessage || curr.belowFloorPriceMessage,
        showPriceWarningModal:
          prev.showPriceWarningModal || curr.showPriceWarningModal,
        priceWarningModalMessage:
          prev.priceWarningModalMessage || curr.priceWarningModalMessage,
      }
    })
  }, [getPriceWarningInfo, submittedFormData])

  const firstAsset = itemsToDisplay[0] as SellAsset | undefined

  if (!paymentAssets || !firstAsset) {
    return undefined
  }

  const assetKey = `${firstAsset.assetContract.address}_${firstAsset.tokenId}`

  const onSubmit = async (data: SellFormSubmitData) => {
    tracker.submit(data)
    setSubmittedFormData(data)
    setIsSubmitting(true)
  }

  const renderForm = () => (
    <NetworkUnsupportedGate>
      {({ handleIfNotSupported }) => (
        <SellForm
          assetKey={assetKey}
          assets={itemsToDisplay}
          defaultValues={defaultFormValues}
          getPriceWarningInfo={getPriceWarningInfo}
          isSubmitting={isSubmitting}
          paymentAssets={paymentAssets}
          setAssets={setAssets}
          setPrice={setPrice}
          setSelectedPaymentAssetRelayId={setSelectedPaymentAssetRelayId}
          submitRef={submitRef}
          tradeLimitsDataKey={tradeLimits}
          variant={isDisplayedInModal ? "compact" : "default"}
          wrappedCurrencySymbol={chain?.wrappedCurrency.symbol ?? "WETH"}
          onSubmit={handleIfNotSupported(onSubmit)}
          onWatch={onWatch}
        />
      )}
    </NetworkUnsupportedGate>
  )

  const listingModalProps: ListingModalProps = {
    assetsToDisplay: itemsToDisplay,
    chain: chain?.identifier,
    cleanupMultichainModal,
    closeMultichainModal,
    firstAsset,
    getAggregatedPriceWarningInfo,
    isMultichainModalOpen,
    isSubmitting,
    itemPath,
    openMultichainModal,
    paymentAssets,
    setIsSubmitting,
    setItemPath,
    submittedFormData,
  }

  return {
    tracker,
    renderForm,
    listingModalProps,
    watchedFormState,
  }
}

type BelowFloorPriceTextProps = {
  warningSeverity?: WarningSeverity
}

const BelowFloorPriceText = styled.div<BelowFloorPriceTextProps>`
  ${props =>
    props.warningSeverity === WarningSeverity.High
      ? css`
          color: ${props.theme.colors.error};
        `
      : css`
          color: ${props.theme.colors.warning};
        `}
`
