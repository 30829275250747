import { sellPageQueries_paymentAssets$data } from "@/lib/graphql/__generated__/sellPageQueries_paymentAssets.graphql"

export const getSelectedPaymentAsset = (
  paymentAssets: sellPageQueries_paymentAssets$data,
  selectedPaymentAssetRelayId: string,
) => {
  const paymentAsset = paymentAssets.find(
    ({ relayId }) => relayId === selectedPaymentAssetRelayId,
  )

  if (!paymentAsset) {
    // TODO(i18n): translate error message
    throw new Error("Selected payment asset not found when it always should be")
  }

  return paymentAsset
}
