import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import {
  Icon,
  useIsLessThanLg,
  UnstyledButton,
  Text,
  Switch,
  RadioButtonGroup,
  Spinner,
  VerticalAligned,
  SpaceBetween,
  Input,
  Skeleton,
} from "@opensea/ui-kit"
import BigNumber from "bignumber.js"
import localforage from "localforage"
import { first, forEach, pickBy, unset } from "lodash"
import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  RegisterOptions,
} from "react-hook-form"
import { useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { variant } from "styled-system"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { LazyAcceptHighestOfferButton } from "@/components/trade/AcceptHighestOfferButton"
import {
  getPaymentAssetOptions,
  PaymentTokenInput,
} from "@/components/trade/PaymentTokenInput"
import { MAX_TOKEN_INPUT_VALUE, MIN_FEE_PRECISION } from "@/constants/index"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { CreatorFeeShowSupport } from "@/features/creator-fees/components/CreatorFeeShowSupport"
import { CreatorFeeToggleGroup } from "@/features/creator-fees/components/CreatorFeeToggleGroup"
import { OrderDurationRangeSelect } from "@/features/orders/components/OrderDurationRangeSelect.react"
import { OrderDurationSelect } from "@/features/orders/components/OrderDurationSelect.react"
import {
  DateRange,
  DateRangeOptionsVariant,
} from "@/features/orders/hooks/useDateRangeOptions"
import { SellAssets } from "@/features/sell/types"
import { getSelectedPaymentAsset } from "@/features/sell/utils/assets"
import { useAddressInput } from "@/hooks/useAddressInput"
import { useForm } from "@/hooks/useForm"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackOptionalCreatorFeeHidden,
  trackOptionalCreatorFeeShown,
  trackOptionalCreatorFeeSubmitted,
} from "@/lib/analytics/events/feeEvents"
import {
  trackSellFormClickShowLess,
  trackSellFormClickShowMore,
} from "@/lib/analytics/events/sellEvents"
import { SellForm_tradeLimits$key } from "@/lib/graphql/__generated__/SellForm_tradeLimits.graphql"
import { sellPageQueries_paymentAssets$data } from "@/lib/graphql/__generated__/sellPageQueries_paymentAssets.graphql"
import { isEthereum } from "@/lib/helpers/chainUtils"
import { adjustPastDateRange } from "@/lib/helpers/datetime"
import {
  basisPointsToPercentage,
  bn,
  display,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import { MapNullable, Maybe, UnreachableCaseError } from "@/lib/helpers/type"
import {
  getIsAboveMinimumListingPriceUsd,
  isValidDateRange,
  isValidNumberWithDecimals,
  validateAddressOrEns,
} from "@/lib/helpers/validation"
import {
  PriceWarningInfo,
  WatchedFormState,
} from "../../hooks/useSellFlow.react"
import { CollectionPriceButtons } from "../CollectionPriceButtons"
import { SellFees } from "../SellFees.react"
import {
  getDefaultAuctionDuration,
  getDefaultListingDuration,
  getIsGreaterThanMinEnglishAuctionPrice,
} from "./utils"

export type SellFormData = {
  auctionType: AuctionType
  endingPrice: string
  includeReservePrice: boolean
  moreOptionsShown: boolean
  optionalCreatorFeePercentage: string
  price: string
  reservedBuyerAddressOrEnsName: string
  reserveForSpecificBuyer: boolean
  reservePrice: string
  selectedPaymentAssetRelayId: string
  type: SellType
  quantity: string
  duration: DateRange
  decimals: number | null
}

export type SellFormDefaultValues = Partial<SellFormData>

type Props = {
  assets: SellAssets
  defaultValues: SellFormDefaultValues
  // Using the isSubmitting form state is awkward as that relies on a promise being returned
  // however, multichain order creation logic is offloaded to a completely separate React component
  isSubmitting?: boolean
  paymentAssets: sellPageQueries_paymentAssets$data
  wrappedCurrencySymbol: string
  onSubmit: (data: SellFormSubmitData) => unknown
  setAssets: Dispatch<SetStateAction<SellAssets>>
  setPrice: (price: number) => unknown
  setSelectedPaymentAssetRelayId: (relayId: string) => unknown
  assetKey: Maybe<string>
  getPriceWarningInfo: (price: string) => PriceWarningInfo
  tradeLimitsDataKey: SellForm_tradeLimits$key | null
  onWatch?: (watchedFormState: WatchedFormState) => unknown
  submitRef?: React.MutableRefObject<HTMLButtonElement | null>
  variant?: SellFormVariant
}

export type SellFormSubmitData = MapNullable<
  Pick<SellFormData, "auctionType" | "type">
> &
  Pick<SellFormData, "selectedPaymentAssetRelayId" | "duration"> & {
    reservedBuyerAddress: string | null
    optionalCreatorFeeBasisPoints: number | null
    price: BigNumber
    endingPrice: BigNumber | null
    quantity: BigNumber
    decimals: number | null
    reservePrice: BigNumber | null
  }

type PriceFormData = Pick<
  SellFormData,
  "price" | "quantity" | "endingPrice" | "reservePrice"
>

type SellFormVariant = "default" | "compact"

const getOrderDurationSelectVariant = (
  variant: SellFormVariant,
): DateRangeOptionsVariant =>
  variant === "compact" ? "quick-listing" : "full-listing"

export const SellForm = ({
  assets,
  defaultValues,
  isSubmitting,
  paymentAssets,
  wrappedCurrencySymbol,
  onSubmit,
  setSelectedPaymentAssetRelayId,
  setPrice,
  assetKey,
  getPriceWarningInfo,
  tradeLimitsDataKey,
  submitRef,
  onWatch,
  variant = "default",
}: Props) => {
  const t = useTranslate("sell")
  const isSmall = useIsLessThanLg()
  const tradeLimits = useFragment(
    graphql`
      fragment SellForm_tradeLimits on TradeLimitsType {
        minimumListingUsdPrice
        minimumEnglishAuctionUsdPrice
      }
    `,
    tradeLimitsDataKey,
  )

  const firstAsset = assets[0]

  const maxCreatorFeeBasisPoints =
    firstAsset.collection.totalCreatorFeeBasisPoints

  const shouldShowOptionalCreatorFee =
    !firstAsset.collection.isCreatorFeesEnforced && maxCreatorFeeBasisPoints

  const {
    register,
    formState: { errors: useFormErrors, isValid, isDirty, dirtyFields },
    control,
    watch,
    setValue,
    setError,
    handleSubmit,
    reset,
    trigger,
  } = useForm<SellFormData>({
    mode: "onChange",
    defaultValues: {
      auctionType: "english",
      endingPrice: "",
      includeReservePrice: false,
      moreOptionsShown: false,
      optionalCreatorFeePercentage: shouldShowOptionalCreatorFee
        ? basisPointsToPercentage(maxCreatorFeeBasisPoints)
        : "",
      price: "",
      decimals: firstAsset.decimals || 0,
      reservedBuyerAddressOrEnsName: "",
      reserveForSpecificBuyer: false,
      reservePrice: "",
      selectedPaymentAssetRelayId: first(paymentAssets)?.relayId,
      type: "fixed",
      quantity: "1",
      duration: getDefaultListingDuration(),

      ...pickBy(defaultValues),
    },
  })

  useEffect(() => {
    if (shouldShowOptionalCreatorFee) {
      trackOptionalCreatorFeeShown({
        source: "item-sell-page",
        isMaxFees: true,
        optionalBasisPoints: maxCreatorFeeBasisPoints,
        maxBasisPoints: maxCreatorFeeBasisPoints,
        percent: 100,
      })
    } else {
      trackOptionalCreatorFeeHidden({ source: "item-sell-page" })
    }
  }, [maxCreatorFeeBasisPoints, shouldShowOptionalCreatorFee])

  // Seems to be some weird edge case where the form isValid but still contains errors
  const errors = !isValid ? useFormErrors : {}

  const [
    auctionType,
    duration,
    includeReservePrice,
    moreOptionsShown,
    optionalCreatorFeePercentage,
    price,
    quantity,
    reservedBuyerAddressOrEnsName,
    reserveForSpecificBuyer,
    selectedPaymentAssetRelayId,
    type,
  ] = watch([
    "auctionType",
    "duration",
    "includeReservePrice",
    "moreOptionsShown",
    "optionalCreatorFeePercentage",
    "price",
    "quantity",
    "reservedBuyerAddressOrEnsName",
    "reserveForSpecificBuyer",
    "selectedPaymentAssetRelayId",
    "type",
  ])

  const { address: reservedBuyerAddress, isResolvingEnsName } = useAddressInput(
    reservedBuyerAddressOrEnsName,
  )

  const isDisabled = Boolean(
    !isValid || !isDirty || isResolvingEnsName || isSubmitting,
  )

  useEffect(() => {
    if (onWatch) {
      onWatch({
        fieldValues: {
          quantity,
          duration,
        },
        disabled: isDisabled,
        dirtyFields,
      })
    }
  }, [onWatch, duration, quantity, isDisabled, dirtyFields])

  useMountEffect(() => {
    if (defaultValues.price) {
      reset(
        (formValues: SellFormData) => ({
          ...formValues,
          price: "",
        }),
        { keepDefaultValues: true },
      )
      setValue("price", defaultValues.price, { shouldDirty: true })
      trigger()
    }
    const applyPresetListingTime = async () => {
      try {
        if (!assetKey) {
          return
        }
        const presetListingTime = await localforage.getItem<number>(
          `${assetKey}_listing_date`,
        )
        if (!presetListingTime) {
          return
        }

        setValue(
          "duration",
          getDefaultListingDuration(new Date(presetListingTime)),
        )
        setValue("moreOptionsShown", true)
      } catch (e) {
        console.error(e)
      }
    }
    applyPresetListingTime()
    setValue("duration", adjustPastDateRange(duration))
  })

  const ownedQuantity = firstAsset.ownedQuantity
  const isFungible = firstAsset.isCurrentlyFungible

  const chain = firstAsset.chain.identifier
  const isSeaportEnabled = useIsSeaportEnabled(chain)
  const auctionsAreSupported = isSeaportEnabled && variant !== "compact"
  const customQuantityIsSupported =
    isFungible && ownedQuantity && bn(ownedQuantity).gt(1)

  const selectedPaymentAsset = getSelectedPaymentAsset(
    paymentAssets,
    selectedPaymentAssetRelayId,
  )
  const isEnglishAuction = auctionType === "english" && type === "auction"

  const validPaymentAssets = paymentAssets.filter(({ symbol, isNative }) => {
    if (isEnglishAuction) {
      return !isNative
    }
    if (symbol === wrappedCurrencySymbol && symbol !== "ETH") {
      // do not allow listings in wrapped payment asset of chain unless it is ETH (special-case for Polygon)
      return false
    }
    return true
  })

  const paymentAssetOptions = getPaymentAssetOptions(validPaymentAssets)
  forEach(paymentAssetOptions, option => {
    unset(option, "avatar")
  })

  useUpdateEffect(() => {
    const paymentAssetRelayIds = validPaymentAssets.map(
      ({ relayId }) => relayId,
    )
    const selectedPaymentAssetRelayId = paymentAssetRelayIds[0]
    setValue("selectedPaymentAssetRelayId", selectedPaymentAssetRelayId)
    switch (type) {
      case "auction":
        setValue("duration", getDefaultAuctionDuration())
        return
      case "fixed":
        setValue("duration", getDefaultListingDuration())
        return
      default:
        // conditional used to avoid conflicts with SellType type,
        // which is set as a "string"
        if (typeof type !== "string") {
          throw new UnreachableCaseError(type)
        }
    }
  }, [type, auctionType])

  useEffect(() => {
    setSelectedPaymentAssetRelayId(selectedPaymentAssetRelayId)
  }, [selectedPaymentAssetRelayId, setSelectedPaymentAssetRelayId])

  useEffect(() => {
    const newPrice = +bn(price) >= 0 ? +bn(price) : 0
    setPrice(newPrice)
  }, [price, setPrice])

  useUpdateEffect(() => {
    if (reservedBuyerAddress === "" && !isResolvingEnsName) {
      setError("reservedBuyerAddressOrEnsName", { message: "Invalid ENS name" })
    }
  }, [reservedBuyerAddress, isResolvingEnsName])

  const priceValidator = {
    ...isValidNumberWithDecimals({
      maxDecimals: selectedPaymentAsset.decimals - MIN_FEE_PRECISION,
    }),
    ...{
      isAboveMinimumListingPriceUsd: getIsAboveMinimumListingPriceUsd(
        chain,
        selectedPaymentAsset.usdPrice,
        bn(tradeLimits?.minimumListingUsdPrice || 0),
      ),
    },
  }
  const quantityValidator = isValidNumberWithDecimals({
    maxDecimals: firstAsset.decimals ?? 0,
  })
  // We use this workaround because we should still be validating for these fields, even if moreOptions is collapsed
  const moreOptionsStyle = { display: moreOptionsShown ? "block" : "none" }

  const isResolvedEnsName =
    reserveForSpecificBuyer &&
    reservedBuyerAddress &&
    reservedBuyerAddress !== reservedBuyerAddressOrEnsName

  const durationError = errors.duration
    ? (errors.duration as FieldError).message
    : undefined

  const sellTypeOptionData = useSellType()

  const renderType = () => {
    return (
      auctionsAreSupported &&
      !isFungible && (
        <SectionFormControl
          className="mb-10"
          label={
            <SpaceBetween className="mb-4">
              <TextHeading $variant={variant} size="small">
                {t("form.saleType", "Choose a type of sale")}
              </TextHeading>
            </SpaceBetween>
          }
          variant={variant}
        >
          <RadioButtonGroup
            defaultValue="fixed"
            name="sell-type"
            onValueChange={value => {
              return (
                value &&
                setValue("type", value, {
                  shouldValidate: true,
                })
              )
            }}
          >
            {sellTypeOptionData.map(option => (
              <RadioButtonGroup.Item key={option.value} value={option.value}>
                <RadioButtonGroup.Item.Content>
                  <RadioButtonGroup.Item.Title>
                    {option.title}
                  </RadioButtonGroup.Item.Title>
                  <RadioButtonGroup.Item.Description>
                    {option.description}
                  </RadioButtonGroup.Item.Description>
                </RadioButtonGroup.Item.Content>
              </RadioButtonGroup.Item>
            ))}
          </RadioButtonGroup>
        </SectionFormControl>
      )
    )
  }

  const renderCollectionPriceButtons = () => {
    return (
      assets.length === 1 && (
        <CollectionPriceButtons
          asset={firstAsset}
          priceConversion={{
            usdPrice: bn(selectedPaymentAsset.usdPrice),
            symbol: selectedPaymentAsset.symbol,
          }}
          variant={variant}
          onClick={({ unit }) => {
            setValue("price", unit.toString(), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
        />
      )
    )
  }

  const optionalCreatorFeeInput = useOptionalCreatorFeeInput({
    maxBasisPoints: maxCreatorFeeBasisPoints,
  })

  const renderOptionalCreatorFee = () => {
    if (!shouldShowOptionalCreatorFee) {
      return null
    }
    const name = "optionalCreatorFeePercentage"
    const { label } = optionalCreatorFeeInput

    const totalCreatorFeePercentageError =
      optionalCreatorFeePercentage && errors[name]?.message

    return (
      <>
        <FormControl
          className="my-4"
          error={totalCreatorFeePercentageError}
          hideLabel
          htmlFor={name}
          label={label}
        >
          <CreatorFeeToggleGroup
            control={control}
            error={Boolean(totalCreatorFeePercentageError)}
            maxBasisPoints={maxCreatorFeeBasisPoints}
            name={name}
            price={
              price
                ? {
                    unit: price,
                    symbol: selectedPaymentAsset.symbol,
                    usd: selectedPaymentAsset.usdPrice,
                  }
                : undefined
            }
          />
        </FormControl>
        <Block marginBottom="16px">
          <CreatorFeeShowSupport basisPoints={maxCreatorFeeBasisPoints} />
        </Block>
      </>
    )
  }

  const renderMoreOptions = () => (
    <>
      <Block>
        <UnstyledButton
          aria-expanded={moreOptionsShown}
          style={{ width: "100%" }}
          onClick={() => {
            const nextMoreOptionsShown = !moreOptionsShown
            setValue("moreOptionsShown", nextMoreOptionsShown)
            if (nextMoreOptionsShown) {
              trackSellFormClickShowMore()
            } else {
              trackSellFormClickShowLess()
            }
          }}
        >
          <SpaceBetween className="w-full">
            <TextHeading $variant={variant} size="small">
              {t("form.moreOptions", "More options")}
            </TextHeading>
            <Icon value={moreOptionsShown ? "expand_less" : "expand_more"} />
          </SpaceBetween>
        </UnstyledButton>
      </Block>

      {type === "fixed" ? (
        <Block marginTop="40px" style={moreOptionsStyle}>
          <Form.Control
            captionRight={
              isResolvedEnsName
                ? t(
                    "form.resolvedTo",
                    "Resolved to {{address}}",
                    {
                      address: reservedBuyerAddress,
                    },
                    { forceString: true },
                  )
                : undefined
            }
            error={
              reserveForSpecificBuyer
                ? errors.reservedBuyerAddressOrEnsName?.message
                : undefined
            }
            label={
              <SpaceBetween>
                {t("form.reserveForBuyer", "Reserve for specific buyer")}
                <Block paddingLeft="10px">
                  <Switch
                    checked={reserveForSpecificBuyer}
                    onCheckedChange={value =>
                      setValue("reserveForSpecificBuyer", value, {
                        shouldValidate: true,
                      })
                    }
                  />
                </Block>
              </SpaceBetween>
            }
            tip={t(
              "form.asSoonAsListed",
              "This {{object}} can be purchased as soon as it's listed",
              {
                object: t("form.item", "item"),
              },
            )}
          >
            {reserveForSpecificBuyer ? (
              <Input
                placeholder="0xf45a189..."
                {...register("reservedBuyerAddressOrEnsName", {
                  validate: {
                    isValidAddress: v => validateAddressOrEns(String(v)),
                  },
                })}
                endEnhancer={
                  isResolvingEnsName ? (
                    <VerticalAligned className="ml-3">
                      <Spinner />
                    </VerticalAligned>
                  ) : undefined
                }
              />
            ) : (
              <></>
            )}
          </Form.Control>
        </Block>
      ) : (
        auctionType === "english" && (
          <Block style={moreOptionsStyle}>
            <Form.Control
              htmlFor="reservePrice"
              label={
                <SpaceBetween>
                  <Flex>
                    {t("form.includeReserveLabel", "Include reserve price")}
                    <InfoIcon
                      overrides={{
                        Button: { style: { marginLeft: "4px" } },
                        Icon: { size: 24 },
                      }}
                      tooltipContent={t(
                        "form.includeReserveDescription",
                        "If you don't receive any bids equal to or greater than your reserve, the auction will end without a sale.",
                      )}
                    />
                  </Flex>
                  <Switch
                    checked={includeReservePrice}
                    id={includeReservePrice ? undefined : "reservePrice"}
                    onCheckedChange={value =>
                      setValue("includeReservePrice", value, {
                        shouldValidate: true,
                      })
                    }
                  />
                </SpaceBetween>
              }
            >
              {includeReservePrice ? (
                renderPaymentTokenInput(
                  t("form.reservePriceLabel", "Reserve price"),
                  "reservePrice",
                  {
                    validate: {
                      ...priceValidator,
                      isGreaterThanStartingPrice: v =>
                        (!bn(price).isNaN() &&
                          bn(v).isGreaterThan(bn(price))) ||
                        t(
                          "form.reserveGreaterThanStartingError",
                          "Reserve price must be greater than starting price",
                        ),
                      isGreaterThanMinEnglishAuctionPrice:
                        getIsGreaterThanMinEnglishAuctionPrice(
                          selectedPaymentAsset,
                          tradeLimits?.minimumEnglishAuctionUsdPrice,
                        ),
                    },
                  },
                )
              ) : (
                <></>
              )}
            </Form.Control>
          </Block>
        )
      )}
    </>
  )

  const renderPaymentTokenInput = (
    inputHeaderLabel: React.ReactNode,
    name: FieldPath<PriceFormData>,
    {
      validate,
      autoFocus,
    }: {
      validate?: RegisterOptions<PriceFormData>["validate"]
      autoFocus?: boolean
      warnBelowFloorPrice?: boolean
    },
  ) => {
    return (
      <>
        <Text className="mb-3" size="medium" weight="semibold">
          {inputHeaderLabel}
        </Text>

        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            const priceWarningInfo = getPriceWarningInfo(field.value)

            return (
              <PaymentTokenInput
                autoFocus={autoFocus}
                error={errors[name]}
                id={field.name}
                name={field.name}
                paymentAssetOptions={paymentAssetOptions}
                paymentAssetRelayId={selectedPaymentAssetRelayId}
                price={field.value}
                quantity={isEthereum(chain) ? Number(quantity) : undefined}
                warning={
                  dirtyFields[field.name] && priceWarningInfo.belowFloorPrice
                    ? priceWarningInfo.belowFloorPriceMessage
                    : undefined
                }
                onChange={field.onChange}
                onChangePaymentAsset={relayId =>
                  setValue("selectedPaymentAssetRelayId", relayId)
                }
              />
            )
          }}
          rules={{
            max: {
              value: MAX_TOKEN_INPUT_VALUE,
              message: t(
                "form.maxAmountPrompt",
                "The amount cannot exceed {{max}}",
                {
                  max: display(MAX_TOKEN_INPUT_VALUE),
                },
                { forceString: true },
              ),
            },
            validate,
          }}
        />
      </>
    )
  }

  const renderQuantityInput = () => {
    return (
      <Input
        placeholder={ownedQuantity ?? undefined}
        type="number"
        {...register("quantity", {
          required: "Please enter a quantity",
          max: ownedQuantity
            ? {
                value: bn(ownedQuantity).toNumber(),
                message: t(
                  "form.maxQuantityPrompt",
                  "The quantity cannot exceed {{max}}",
                  {
                    max: display(ownedQuantity),
                  },
                  { forceString: true },
                ),
              }
            : undefined,
          validate: {
            ...quantityValidator,
            isNotZero: val =>
              val !== "0" ||
              t(
                "form.quantityNonZeroPrompt",
                "Quantity must be greater than 0",
              ),
          },
        })}
      />
    )
  }

  const renderFixedTypeFields = () => (
    <>
      {customQuantityIsSupported && (
        <Form.Control
          captionRight={t("form.available", "{{quantity}} available", {
            quantity: display(ownedQuantity),
          })}
          error={errors.quantity?.message}
          htmlFor="quantity"
          label={
            <FormLabelBlock variant={variant}>
              <TextHeading $variant={variant} size="small">
                {t("form.numberOfItems", "# of items")}
              </TextHeading>
            </FormLabelBlock>
          }
        >
          {renderQuantityInput()}
        </Form.Control>
      )}

      <SectionFormControl
        className="mb-10"
        htmlFor="price"
        label={
          <FormLabelBlock alignItems="center" display="flex" variant={variant}>
            <TextHeading
              $variant={variant}
              className={variant === "compact" ? "mr-1" : "mr-2"}
              size="small"
            >
              {isFungible
                ? t("form.pricePerItemLabel", "Set a price per item")
                : t("form.priceLabel", "Set a price")}
            </TextHeading>
            <InfoIcon
              overrides={{ Icon: { size: variant === "compact" ? 16 : 20 } }}
              tooltipContent={t(
                "form.priceImmutabilityWarning",
                "You will not be able to change the price after listing. If you'd like to change the price, you will need to create a new listing.",
              )}
            />
          </FormLabelBlock>
        }
        variant={variant}
      >
        <>
          {renderCollectionPriceButtons()}
          {renderPaymentTokenInput(
            t("form.startingPriceLabel", "Starting price"),
            "price",
            {
              validate: priceValidator,
              autoFocus: true,
            },
          )}
        </>
      </SectionFormControl>

      <ControlledDateRange
        control={control}
        errorMessage={durationError}
        variant={variant}
      />
    </>
  )

  const renderAuctionTypeFields = () => {
    return (
      <>
        {customQuantityIsSupported && (
          <Form.Control
            captionRight={t("form.available", "{{quantity}} available", {
              quantity: ownedQuantity,
            })}
            error={errors.quantity?.message}
            htmlFor="quantity"
            label={t("form.quantityLabel", "Quantity")}
          >
            {renderQuantityInput()}
          </Form.Control>
        )}

        <Form.Control
          htmlFor="price"
          label={
            <>
              <FormLabelBlock variant={variant}>
                <TextHeading $variant={variant} size="small">
                  {t("form.priceLabel", "Set a price")}
                </TextHeading>
              </FormLabelBlock>
            </>
          }
        >
          <>
            {renderCollectionPriceButtons()}

            {renderPaymentTokenInput(
              t("form.startingPriceLabel", "Starting price"),
              "price",
              {
                validate: {
                  ...priceValidator,
                },
                warnBelowFloorPrice: true,
              },
            )}
          </>
        </Form.Control>
        <ControlledDateRange
          control={control}
          errorMessage={durationError}
          variant={variant}
        />
      </>
    )
  }

  const showMoreOptionsButton =
    variant !== "compact" && (type === "fixed" || auctionType === "english")

  const resolveInternalValues = (data: SellFormData): SellFormSubmitData => {
    const auctionType = data.type === "auction" ? "english" : null
    const quantity = bn(data.quantity)
    const price = bn(data.price)
    const endingPrice = null
    const optionalCreatorFeeBasisPoints = data.optionalCreatorFeePercentage
      ? percentageToBasisPoints(data.optionalCreatorFeePercentage)
      : null

    return {
      auctionType: data.type === "auction" ? data.auctionType : null,
      endingPrice,
      price,
      quantity,
      optionalCreatorFeeBasisPoints,
      decimals: data.decimals,
      reservePrice:
        auctionType === "english" && includeReservePrice
          ? bn(data.reservePrice)
          : null,
      reservedBuyerAddress: data.reserveForSpecificBuyer
        ? reservedBuyerAddress
        : null,
      selectedPaymentAssetRelayId: data.selectedPaymentAssetRelayId,
      type: data.type,
      duration: data.duration,
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(data => {
        const resolvedValues = resolveInternalValues(data)
        const { optionalCreatorFeeBasisPoints } = resolvedValues
        if (
          shouldShowOptionalCreatorFee &&
          optionalCreatorFeeBasisPoints !== null
        ) {
          trackOptionalCreatorFeeSubmitted({
            source: "item-sell-page",
            isMaxFees:
              optionalCreatorFeeBasisPoints === maxCreatorFeeBasisPoints,
            optionalBasisPoints: optionalCreatorFeeBasisPoints,
            maxBasisPoints: maxCreatorFeeBasisPoints,
            percent: optionalCreatorFeeBasisPoints / maxCreatorFeeBasisPoints,
          })
        }
        return onSubmit(resolvedValues)
      })}
    >
      {renderType()}

      {type === "fixed" ? renderFixedTypeFields() : renderAuctionTypeFields()}
      {showMoreOptionsButton && renderMoreOptions()}

      <Block marginTop={moreOptionsShown ? "28px" : "40px"}>
        <SellFees
          creatorFeeInput={renderOptionalCreatorFee()}
          dataKey={firstAsset}
          isSingleCollection
          optionalCreatorFeeBasisPoints={
            optionalCreatorFeePercentage
              ? percentageToBasisPoints(optionalCreatorFeePercentage)
              : undefined
          }
          price={price ? bn(price) : undefined}
          quantity={quantity ? bn(quantity) : undefined}
          symbol={selectedPaymentAsset.symbol}
          usdSpotPrice={selectedPaymentAsset.usdSpotPrice}
          variant={variant}
        />
      </Block>

      <Block
        display={variant === "compact" ? "none" : undefined}
        margin="40px 0"
      >
        <Button
          className="w-full"
          disabled={isDisabled}
          isLoading={isSubmitting}
          ref={submitRef}
          type="submit"
        >
          {t("form.completeListingCTA", "Complete listing")}
        </Button>

        {isSmall && assets.length === 1 && !assets[0].acceptOfferDisabled ? (
          <LazyAcceptHighestOfferButton
            chain={firstAsset.chain.identifier}
            className="mt-4 w-full"
            contractAddress={firstAsset.assetContract.address}
            source="sell page"
            suspenseFallback={
              <Skeleton>
                <Skeleton.Block className="h-[60px] w-full rounded-xl" />
              </Skeleton>
            }
            tokenId={firstAsset.tokenId}
          />
        ) : null}
      </Block>
    </Form>
  )
}

const ControlledDateRange = ({
  control,
  errorMessage,
  variant,
}: {
  control?: Control<SellFormData>
  errorMessage?: string
  variant: SellFormVariant
}) => {
  const t = useTranslate("sell")
  const orderDurationSelectVariant = getOrderDurationSelectVariant(variant)

  return (
    <Controller
      control={control}
      name="duration"
      render={({ field }) => {
        return (
          <SectionFormControl
            error={errorMessage}
            htmlFor="duration"
            label={
              <FormLabelBlock variant={variant}>
                <TextHeading $variant={variant} size="small">
                  {t("form.setDuration", "Duration")}
                </TextHeading>
              </FormLabelBlock>
            }
            variant={variant}
          >
            {variant === "compact" ? (
              <OrderDurationSelect
                duration={field.value}
                id={field.name}
                variant={orderDurationSelectVariant}
                onChange={field.onChange}
              />
            ) : (
              <OrderDurationRangeSelect
                duration={field.value}
                id={field.name}
                variant={orderDurationSelectVariant}
                onChange={field.onChange}
              />
            )}
          </SectionFormControl>
        )
      }}
      rules={{
        validate: {
          ...isValidDateRange,
        },
      }}
    />
  )
}

const SectionFormControl = styled(Form.Control)<{
  variant: SellFormVariant
}>`
  ${variant({
    variants: {
      compact: {
        marginBottom: "20px",
      },
      default: {
        marginBottom: "40px",
      },
    },
  })}
`

const FormLabelBlock = styled(Block)<{ variant: SellFormVariant }>`
  ${variant({
    variants: {
      compact: {
        marginBottom: "0px",
      },
      default: {
        marginBottom: "24px",
      },
    },
  })}
`

export const TextHeading = styled(Text.Heading)<{ $variant: SellFormVariant }>`
  ${variant({
    prop: "$variant",
    variants: {
      compact: {
        fontSize: "16px",
      },
    },
  })}
`

type AuctionType = string

const useSellType = () => {
  const t = useTranslate("sell")

  const sellTypeOptionData: readonly SellTypeOption[] = useMemo(
    () => [
      {
        title: t("form.fixedPriceCTA", "Fixed price"),
        description: t(
          "form.fixedPriceCTADescription",
          "The item is listed at the price you set.",
        ),
        value: "fixed",
      },
      {
        title: t("form.englishAuctionCTA", "Sell to highest bidder"),
        description: t(
          "form.timedAuctionCTADescription",
          "The item is listed for auction. {{learnMore}}",
          {
            learnMore: (
              <Link href="https://support.opensea.io/articles/8867012">
                Learn More
              </Link>
            ),
          },
        ),
        value: "auction",
      },
    ],
    [t],
  )

  return sellTypeOptionData
}

type SellTypeOption = {
  title: string
  description: string | JSX.Element
  value: string
}

type SellType = string
