import { createContext, useContext } from "react"
import { AnalyticsTracker } from "@/lib/analytics/AnalyticsTracker"
import { SellFormSubmitData } from "./components/SellForm"

type SellAnalyticsParams = {
  eventSource: string | undefined

  assetIds?: string[]
  collectionSlugs?: string[]
}

export class SellAnalyticsTracker extends AnalyticsTracker<SellAnalyticsParams> {
  open = () => this.track("sell-flow open")
  addAsset = (assetId: string) => this.track("sell-flow add asset", { assetId })
  removeAsset = (assetId: string) =>
    this.track("sell-flow remove asset", { assetId })
  submit = (submitData: SellFormSubmitData) =>
    this.track("sell-flow submit", submitData)

  preRenderAcceptFiatPrompt = (extra: {
    isAuthenticated: boolean
    hasActiveWalletAddress: boolean
  }) => this.track("sell-flow  pre-render accept fiat prompt", extra)
  viewAcceptFiatPrompt = () => this.track("sell-flow view accept fiat prompt")
  viewFiatLimitPrompt = () => this.track("sell-flow view fiat limit prompt")
  setFloorPrice = () => this.track("sell-flow set floor price")
  setTopTraitPrice = () => this.track("sell-flow set top trait price")
}

type SellAnalyticsContext = {
  tracker: SellAnalyticsTracker
}

const DEFAULT_SELL_ANALYTICS_CONTEXT: SellAnalyticsContext = {
  tracker: new SellAnalyticsTracker({ eventSource: "SellAnalytics" }),
}

export const SellAnalyticsContext = createContext(
  DEFAULT_SELL_ANALYTICS_CONTEXT,
)

export const useSellAnalyticsContext = () => useContext(SellAnalyticsContext)
