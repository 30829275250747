import { graphql } from "relay-runtime"

export const SellPagePaymentAssetsFragment = graphql`
  fragment sellPageQueries_paymentAssets on PaymentAssetType
  @relay(plural: true) {
    relayId
    symbol
    isNative
    address
    decimals
    displayImageUrl
    usdSpotPrice
    ...ConfirmationItem_payment_asset
    ethPrice
    usdPrice
    ...utils_PaymentAssetOption
    ...PriceTag_paymentAsset
  }
`

export const SellPageAssetFragment = graphql`
  fragment sellPageQueries_asset on AssetType
  @relay(plural: true)
  @argumentDefinitions(
    identity: { type: "IdentityInputType", defaultValue: {} }
  ) {
    includesSharedStorefront
    name
    displayImageUrl
    relayId
    tokenId
    ownedQuantity(identity: $identity)
    isCurrentlyFungible
    decimals
    acceptOfferDisabled {
      __typename
    }
    assetContract {
      address
    }
    chain {
      identifier
    }
    collection {
      name
      slug
      statsV2 {
        floorPrice {
          unit
          usd
        }
      }
      nativePaymentAsset {
        symbol
      }
      isVerified
      isCreatorFeesEnforced
      totalCreatorFeeBasisPoints
    }
    ...AssetMedia_asset
    ...AssetSellPreviewFooter_assets
    ...asset_url
    ...asset_display_name
    ...AssetItem_asset
    ...ConfirmationItem_assets
    ...CollectionPriceButtons_asset
    ...SellFees_data
  }
`
