/**
 * @generated SignedSource<<2172b236518ec227027f1ee87cbf7d2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellFees_data$data = {
  readonly isCurrentlyFungible: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"useItemFees_item">;
  readonly " $fragmentType": "SellFees_data";
};
export type SellFees_data$key = {
  readonly " $data"?: SellFees_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellFees_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellFees_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentlyFungible",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useItemFees_item"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "8ae53d40b551d4bc48c3dfc11fe069ee";

export default node;
