/**
 * @generated SignedSource<<c418c3750377f47589243fd19588add7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CollectionPriceButtonsQuery$variables = {
  collectionSlug: string;
};
export type CollectionPriceButtonsQuery$data = {
  readonly collection: {
    readonly statsV2: {
      readonly floorPrice: {
        readonly symbol: string;
        readonly unit: string;
        readonly usd: string;
      } | null;
    };
  } | null;
};
export type CollectionPriceButtonsQuery = {
  response: CollectionPriceButtonsQuery$data;
  variables: CollectionPriceButtonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectionSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collectionSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionPriceButtonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionPriceButtonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95bea0637335fac8d010c7ee8354396e",
    "id": null,
    "metadata": {},
    "name": "CollectionPriceButtonsQuery",
    "operationKind": "query",
    "text": "query CollectionPriceButtonsQuery(\n  $collectionSlug: CollectionSlug!\n) {\n  collection(collection: $collectionSlug) {\n    statsV2 {\n      floorPrice {\n        unit\n        symbol\n        usd\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "767f691ae1c48be8234290db50d92854";

export default node;
