import {
  Price,
  PriceConversion,
} from "@/features/sell/components/CollectionPriceButtons/types"

export const convertPrice = (
  price: Price,
  conversion: PriceConversion,
): Price => {
  if (price.symbol === conversion.symbol) {
    return price
  }

  // Arbitrarily high number, can happen due to e.g. MATIC/OPT/ARB to ETH conversions
  const unit = price.usd.div(conversion.usdPrice).decimalPlaces(12)
  return {
    unit,
    usd: price.usd,
    symbol: conversion.symbol,
  }
}
