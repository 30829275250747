/**
 * @generated SignedSource<<1fa26f5181013a1a736c59a6b1314e0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sellPageQueries_paymentAssets$data = ReadonlyArray<{
  readonly address: string;
  readonly decimals: number;
  readonly displayImageUrl: string;
  readonly ethPrice: string;
  readonly isNative: boolean;
  readonly relayId: string;
  readonly symbol: string;
  readonly usdPrice: string;
  readonly usdSpotPrice: number;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_payment_asset" | "PriceTag_paymentAsset" | "utils_PaymentAssetOption">;
  readonly " $fragmentType": "sellPageQueries_paymentAssets";
}>;
export type sellPageQueries_paymentAssets$key = ReadonlyArray<{
  readonly " $data"?: sellPageQueries_paymentAssets$data;
  readonly " $fragmentSpreads": FragmentRefs<"sellPageQueries_paymentAssets">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimals",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayImageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usdSpotPrice",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "sellPageQueries_paymentAssets",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmationItem_payment_asset"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ethPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usdPrice",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "utils_PaymentAssetOption",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceTag_paymentAsset"
    }
  ],
  "type": "PaymentAssetType",
  "abstractKey": null
};
})();

(node as any).hash = "5daff849e46c25ef461a754020c3b98d";

export default node;
