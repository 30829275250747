import React from "react"
import {
  Icon,
  Separator,
  SpaceBetween,
  Text,
  classNames,
  FlexColumn,
} from "@opensea/ui-kit"
import BigNumber from "bignumber.js"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Tooltip } from "@/design-system/Tooltip"
import { useItemFees } from "@/hooks/useItemFees"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import { SellFees_data$key } from "@/lib/graphql/__generated__/SellFees_data.graphql"
import {
  display,
  bn,
  displayUSD,
  basisPointsToPercentage,
} from "@/lib/helpers/numberUtils"
import { TextHeading } from "./SellForm"

type SellFeesVariant = "default" | "compact"

type Props = {
  dataKey: SellFees_data$key
  variant?: SellFeesVariant
  isSingleCollection: boolean
  price?: BigNumber
  quantity?: BigNumber
  symbol: string
  optionalCreatorFeeBasisPoints?: number
  usdSpotPrice: number | null
  creatorFeeInput: React.ReactNode
}

const OLD_SERVICE_FEE_BASIS_POINTS = 250

export const SellFees = ({
  dataKey,
  isSingleCollection,
  optionalCreatorFeeBasisPoints,
  price,
  quantity,
  symbol,
  usdSpotPrice,
  creatorFeeInput,
  variant = "default",
}: Props) => {
  const t = useTranslate("sell")
  const asset = useFragment(
    graphql`
      fragment SellFees_data on AssetType {
        isCurrentlyFungible
        ...useItemFees_item
      }
    `,
    dataKey,
  )

  const totalPrice = price?.times(quantity ?? 1)
  const isFungible = asset.isCurrentlyFungible

  const {
    creatorFeePercentage,
    openseaSellerFee,
    openseaSellerFeePercentage,
    quantityAfterFees,
    maxCreatorFee,
  } = useItemFees(asset, totalPrice, optionalCreatorFeeBasisPoints)

  const quantityAfterFeesUsd = usdSpotPrice
    ? bn(quantityAfterFees).multipliedBy(usdSpotPrice)
    : undefined

  const showLowerServiceFee = openseaSellerFee < OLD_SERVICE_FEE_BASIS_POINTS

  const creatorFeeInputProps = useOptionalCreatorFeeInput({
    maxBasisPoints: maxCreatorFee ?? 0,
  })

  return (
    <Block>
      {variant !== "compact" && (
        <SpaceBetween className="mb-6">
          <Block>
            <Text.Heading asChild size="small">
              <div>{t("sellFees.summary", "Summary")}</div>
            </Text.Heading>
          </Block>
        </SpaceBetween>
      )}
      <SpaceBetween className="mb-2 flex-wrap">
        <Text asChild className="pr-6" size="medium">
          <div>
            {isFungible
              ? t("sellFees.totalPrice", "Total price")
              : t("sellFees.price", "Listing price")}
          </div>
        </Text>
        <Text asChild size="medium">
          <div>
            {totalPrice
              ? display(totalPrice.toString(), symbol)
              : EMPTY_PRICE_DISPLAY}{" "}
            {symbol}
          </div>
        </Text>
      </SpaceBetween>

      <Separator className="my-4" />

      <SpaceBetween className="mb-2">
        <Text asChild size="medium">
          <div>{t("sellFees.openseaFee", "OpenSea fee")}</div>
        </Text>
        <Block>
          {showLowerServiceFee && (
            <Text className="mr-2 text-secondary line-through" size="medium">
              {basisPointsToPercentage(OLD_SERVICE_FEE_BASIS_POINTS)}%
            </Text>
          )}
          <Text
            color={showLowerServiceFee ? "success" : undefined}
            size="medium"
          >
            {openseaSellerFeePercentage}
          </Text>
        </Block>
      </SpaceBetween>

      <Separator className="my-4" />

      {creatorFeePercentage !== null && isSingleCollection && (
        <SpaceBetween
          className={classNames("mb-0", variant === "compact" && "mb-2")}
        >
          <Flex alignItems="center">
            <Text.Body>{creatorFeeInputProps.label}</Text.Body>
            <Tooltip content={creatorFeeInputProps.tooltipContent} interactive>
              <Icon
                className="ml-1 cursor-pointer text-secondary"
                size={14}
                value="info"
              />
            </Tooltip>
          </Flex>

          <Text asChild size="medium">
            <div>
              {creatorFeePercentage
                ? creatorFeePercentage
                : EMPTY_PRICE_DISPLAY}
            </div>
          </Text>
        </SpaceBetween>
      )}

      {creatorFeeInput}

      <Separator className="my-6" />

      <SpaceBetween className="flex-wrap">
        <TextHeading $variant={variant} className="pr-6" size="small">
          {t("sellFees.totalPotentialEarnings", "Total potential earnings")}
        </TextHeading>
        <FlexColumn className="items-end">
          <TextHeading $variant={variant} size="small">
            {price ? display(quantityAfterFees) : EMPTY_PRICE_DISPLAY} {symbol}
          </TextHeading>
          {price && quantityAfterFeesUsd && (
            <Caption>${displayUSD(quantityAfterFeesUsd)} USD</Caption>
          )}
        </FlexColumn>
      </SpaceBetween>
    </Block>
  )
}

const Caption = styled(Text.Body).attrs({
  size: "small",
})`
  color: ${props => props.theme.colors.text.secondary};
  display: flex;
  justify-content: flex-start;
`
