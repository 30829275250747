/**
 * @generated SignedSource<<2327895994b4ea0da67580da80936b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSellFlow_tradeLimits$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SellForm_tradeLimits">;
  readonly " $fragmentType": "useSellFlow_tradeLimits";
};
export type useSellFlow_tradeLimits$key = {
  readonly " $data"?: useSellFlow_tradeLimits$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSellFlow_tradeLimits">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSellFlow_tradeLimits",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellForm_tradeLimits"
    }
  ],
  "type": "TradeLimitsType",
  "abstractKey": null
};

(node as any).hash = "b27485743901f866ce9520b7df4c23f0";

export default node;
