import React from "react"
import { Text, Skeleton } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import styled, { css } from "styled-components"
import { variant } from "styled-system"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Tooltip } from "@/design-system/Tooltip"
import { useSellAnalyticsContext } from "@/features/sell/analytics"
import {
  Price,
  PriceConversion,
} from "@/features/sell/components/CollectionPriceButtons/types"
import { convertPrice } from "@/features/sell/components/CollectionPriceButtons/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionPriceButtons_asset$key } from "@/lib/graphql/__generated__/CollectionPriceButtons_asset.graphql"
import {
  CollectionPriceButtonsNftQuery,
  CollectionPriceButtonsNftQuery$variables,
} from "@/lib/graphql/__generated__/CollectionPriceButtonsNftQuery.graphql"
import {
  CollectionPriceButtonsQuery,
  CollectionPriceButtonsQuery$variables,
} from "@/lib/graphql/__generated__/CollectionPriceButtonsQuery.graphql"
import {
  bn,
  roundAboveMin,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"

type Variant = "default" | "compact"

type BaseProps = {
  onClick: (price: Price) => unknown
  priceConversion: PriceConversion | null
  variant?: Variant
}

type Props = BaseProps & {
  asset: CollectionPriceButtons_asset$key
}

type LazyProps = BaseProps &
  CollectionPriceButtonsQuery$variables & {
    nft: CollectionPriceButtonsNftQuery$variables
  }

const LazyCollectionPriceButtons = ({
  collectionSlug,
  nft: nftVariables,
  onClick,
  priceConversion,
  variant = "default",
}: LazyProps) => {
  const t = useTranslate("sell")
  const { tracker } = useSellAnalyticsContext()

  const { collection } = useLazyLoadQuery<CollectionPriceButtonsQuery>(
    graphql`
      query CollectionPriceButtonsQuery($collectionSlug: CollectionSlug!) {
        collection(collection: $collectionSlug) {
          statsV2 {
            floorPrice {
              unit
              symbol
              usd
            }
          }
        }
      }
    `,
    { collectionSlug },
  )

  const { nft } = useLazyLoadQuery<CollectionPriceButtonsNftQuery>(
    graphql`
      query CollectionPriceButtonsNftQuery(
        $tokenId: String!
        $contractAddress: AddressScalar!
        $chain: ChainScalar!
      ) {
        nft(
          tokenId: $tokenId
          contractAddress: $contractAddress
          chain: $chain
        ) {
          topTraitFloor {
            trait {
              traitType
              value
            }
            floor {
              unit
              symbol
              usd
            }
          }
        }
      }
    `,
    { ...nftVariables },
  )

  if (!collection) {
    return null
  }

  const floorPrice = collection.statsV2.floorPrice
  const { topTraitFloor } = nft

  if (!floorPrice && !topTraitFloor) {
    return null
  }

  return (
    <Container variant={variant}>
      {floorPrice && (
        <StyledButton
          $variant={variant}
          onClick={() => {
            tracker.setFloorPrice()
            const price = {
              unit: bn(floorPrice.unit),
              symbol: floorPrice.symbol,
              usd: bn(floorPrice.usd),
            }
            onClick(
              priceConversion ? convertPrice(price, priceConversion) : price,
            )
          }}
        >
          <ButtonText>
            {t("collectionPriceButton.priceFloor", "Floor")}
          </ButtonText>
          <ButtonSubtext>
            {roundAboveMin(floorPrice.unit, MAX_DISPLAYED_DECIMAL_PLACES)}{" "}
            {floorPrice.symbol}
          </ButtonSubtext>
        </StyledButton>
      )}
      {topTraitFloor && (
        <Tooltip
          content={`${topTraitFloor.trait.traitType}: ${topTraitFloor.trait.value}`}
        >
          <StyledButton
            $variant={variant}
            onClick={() => {
              tracker.setTopTraitPrice()
              const price = {
                unit: bn(topTraitFloor.floor.unit),
                symbol: topTraitFloor.floor.symbol,
                usd: bn(topTraitFloor.floor.usd),
              }
              onClick(
                priceConversion ? convertPrice(price, priceConversion) : price,
              )
            }}
          >
            <ButtonText>
              {t("collectionPriceButton.topTrait", "Top trait")}
            </ButtonText>
            <ButtonSubtext>
              {roundAboveMin(
                topTraitFloor.floor.unit,
                MAX_DISPLAYED_DECIMAL_PLACES,
              )}{" "}
              {topTraitFloor.floor.symbol}
            </ButtonSubtext>
          </StyledButton>
        </Tooltip>
      )}
    </Container>
  )
}

export const CollectionPriceButtons = ({
  asset: assetKey,
  variant,
  ...baseProps
}: Props) => {
  const t = useTranslate("sell")
  const asset = useFragment(
    graphql`
      fragment CollectionPriceButtons_asset on AssetType {
        tokenId
        isCurrentlyFungible
        assetContract {
          address
        }
        chain {
          identifier
        }
        collection {
          slug
        }
      }
    `,
    assetKey,
  )

  if (asset.isCurrentlyFungible) {
    return null
  }

  return (
    <ErrorBoundary fallback={() => <></>}>
      <SsrSuspense
        fallback={
          <Container variant={variant}>
            <StyledButton $variant={variant ?? "default"}>
              <ButtonText>
                {t("collectionPriceButton.priceFloor", "Floor")}
              </ButtonText>
              <ButtonSubtext>
                <Skeleton.Line className="w-[60px]" />
              </ButtonSubtext>
            </StyledButton>
            <StyledButton $variant={variant ?? "default"}>
              <ButtonText>
                {t("collectionPriceButton.topTrait", "Top trait")}
              </ButtonText>
              <ButtonSubtext>
                <Skeleton.Line className="w-[60px]" />
              </ButtonSubtext>
            </StyledButton>
          </Container>
        }
      >
        <LazyCollectionPriceButtons
          collectionSlug={asset.collection.slug}
          nft={{
            chain: asset.chain.identifier,
            contractAddress: asset.assetContract.address,
            tokenId: asset.tokenId,
          }}
          variant={variant}
          {...baseProps}
        />
      </SsrSuspense>
    </ErrorBoundary>
  )
}

const Container = styled(Flex)<{
  variant?: "default" | "compact"
}>`
  flex-wrap: wrap;
  grid-gap: 16px;
  margin: 0 0 24px;

  ${variant({
    variants: {
      compact: {
        marginY: "12px",
      },
    },
  })}
`
const StyledButton = styled(Button).attrs<{ $variant: Variant }>({
  variant: "secondary",
})<{ $variant: Variant }>`
  align-items: center;
  flex: 1 1 160px;
  flex-direction: column;
  min-height: 48px;

  ${props =>
    props.$variant === "compact" &&
    media({
      sm: css`
        justify-content: center;
        flex-direction: row;
        font-size: 14px;
        gap: 8px;
      `,
    })}
`

const ButtonText = styled(Text).attrs({
  className: "text-inherit whitespace-nowrap",
  size: "small",
  weight: "semibold",
})``

const ButtonSubtext = styled(Text).attrs({
  className: "text-secondary whitespace-nowrap",
  size: "small",
})``
